import React from 'react';
import {
  ChakraProvider,
  Box,
  Image,
  Center,
  extendTheme,
  Heading,
  Text,
  List,
  ListItem,
  ListIcon,
  Divider,
} from '@chakra-ui/react';

import {
  PhoneIcon,
  EmailIcon,
  ChatIcon
} from '@chakra-ui/icons'

import Testimonial from './Components/testimonial/testimonial';
import { HamburgerIcon, InfoIcon } from '@chakra-ui/icons'

function App() {

const theme = extendTheme({
  //Set the background to all black
  colors: {
    cc: {
      boxGray: "#6c6f74",
      primary: "#454545",
      secondary: "#e2e2e2",
      yellow: "#f4c65d",
    }
  },
  styles: {
    global: {
      'html, body': {
        bg: "cc.primary",
      }
    }
  }
})

  return (
    <ChakraProvider theme={theme}>
    
      <Center mt="2rem" ml="2rem" mr="2rem">
        <Image 
          // src='/Images/Logo.png'
          src='/Images/Logo_Full_Color.png'
          boxSize='auto' 
          objectFit='cover'
        />
      </Center>

      <Center mb="4rem" ml="2rem" mr="2rem">
        <Image 
          // src='/Images/Logo.png'
          src='/Images/Logo_Full_Text.png'
          boxSize='auto' 
          objectFit='cover'
        />
      </Center>

      <Center m="1rem" color="cc.secondary" textAlign='center'>
        <Heading>Building The Nest! Check back later!</Heading>
      </Center>
      <Center m="1rem" color="cc.secondary" textAlign='center'>
        <Text></Text>
      </Center>
      
      <Center>
        <Text color="cc.secondary" m="1rem" textAlign="center">
          (Click to open your Email/Phone/Text app)</Text>
      </Center>
      <Center mb="4rem">
        <List spacing={3} color="cc.secondary" textAlign="center">
          <ListItem _hover={{fontWeight: 'bold'}} _active={{fontWeight: 'bold'}}>
            <ListIcon as={PhoneIcon} color="cc.yellow"/>
            Call: <a href="tel://+15015383898">501-538-3898</a>
          </ListItem>
          <ListItem _hover={{fontWeight: 'bold'}} _active={{fontWeight: 'bold'}}>
          <ListIcon as={ChatIcon} color="cc.yellow"/>
            Text: <a href="sms://+15015383898">501-538-3898</a>
          </ListItem>
          <ListItem _hover={{fontWeight: 'bold'}} _active={{fontWeight: 'bold'}}>
            <ListIcon as={EmailIcon} color="cc.yellow"/>
            Email: <a href = "mailto: cranencrow@gmail.com">CraneNCrow@gmail.com</a>
          </ListItem>
        </List>
      </Center>
    </ChakraProvider>
  );
}

export default App;
